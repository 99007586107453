import React, { Component } from 'react';
import moment from 'moment';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation } from 'swiper/core';
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  XCircle,
} from 'react-feather';
import Check from '../check';
import BannerComponent from './bannerComponent';
import AgePicker from '../agePicker';

SwiperCore.use([Navigation]);

class Event extends Component {
  state = {
    selected: 19,
    calendarIndex: 0,
    faqs: [
      {
        title: 'Is SKY Fest COVID safe? ',
        content:
          'All COVID guidelines and protocols will be observed at SKY FEST. Masks will be provided and sanitation stations will be available on the premises.',
      },
    ],
    activeFAQ: 0,
    mobile:
      typeof window !== 'undefined' &&
      /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      ),
    bookingData: {},
    inputs: {
      eventId: 3,
      scan_count: 0,
      eventSlotId: 29,
    },
    errors: {},
    showParent: false,
    bookingError: false,
    loading: false,
    showAgeLimitPopup: false,
    initialDate: moment().subtract(13, 'years').toDate(),
  };
  render() {
    return (
      <div className='fest-event'>
        <div className='container pb-5 '>
          <div className='row'>
            <div className='col-md-5 info-banners d-none d-md-block'>
              {this.props.cards.map((d, i) => (
                <div className={'banner-item my-5 item-' + i} key={i}>
                  <div className='banner-content '>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: d.content,
                      }}></div>
                  </div>

                  {d.banner && (
                    <BannerComponent
                      value={d.banner}
                      type={1}></BannerComponent>
                  )}
                </div>
              ))}
            </div>

            <div className='col-md-6'>
              <form
                className='event-form shadow p-4'
                onSubmit={(e) => {
                  e.preventDefault();
                  setTimeout(() => {
                    this.book();
                  }, 0);
                }}>
                <h3 className='mb-4 text-primary form-title'>REGISTER NOW</h3>

                {/* <span className='text-danger'>Registration closed</span> */}

                <div className='form-group mb-3'>
                  <label className='text-primary form-label'>FULL NAME</label>
                  <input
                    type='text'
                    disabled={false}
                    onChange={(e) => {
                      let { inputs } = this.state;
                      inputs.name = e.target.value;
                      this.setState({ inputs });
                    }}
                    className='form-control'
                    required={true}
                  />
                </div>
                <div className='row'>
                  <div className='form-group mb-3 col-md-6'>
                    <label className='text-primary form-label'>
                      MOBILE NUMBER
                    </label>
                    <input
                      type='number'
                      disabled={false}
                      onChange={(e) => {
                        let { inputs } = this.state;
                        inputs.phone = e.target.value;
                        this.setState({ inputs, errors: {} });
                      }}
                      className='form-control'
                      required={true}
                    />

                    {this.state.errors.phone && (
                      <small className='text-red' id='phone-error'>
                        Phone Number is not valid
                      </small>
                    )}
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label className='text-primary form-label'>
                      PARENT/GUARDIAN NUMBER
                    </label>
                    <input
                      type='number'
                      disabled={false}
                      onChange={(e) => {
                        let { inputs } = this.state;
                        inputs.phone_parent = e.target.value;
                        this.setState({ inputs, errors: {} });
                      }}
                      className='form-control'
                      required={true}
                    />

                    {this.state.errors.phone_parent && (
                      <small className='text-red' id='phone_parent-error'>
                        Phone Number is not valid
                      </small>
                    )}
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label className='text-primary form-label'>
                      DATE OF BIRTH
                    </label>
                    <div className='date-input'>
                      <AgePicker
                        onChange={(date) => {
                          let { inputs } = this.state;
                          inputs.dob = date;
                          this.setState({ inputs, errors: {} });
                        }}
                        onBlur={(date) => {
                          let { inputs } = this.state;
                          if (
                            moment().diff(date, 'years') > 19 ||
                            moment().diff(date, 'years') < 12
                          ) {
                            this.setState({ showAgeLimitPopup: true });
                          } else {
                            this.setState({ showAgeLimitPopup: false });
                          }
                        }}></AgePicker>
                    </div>

                    {this.state.errors.dob && (
                      <small className='text-red' id='dob-error'>
                        {this.state.errors.dob}
                      </small>
                    )}
                  </div>

                  <div className='form-group mb-3 col-md-6'>
                    <label className='text-primary form-label'>GENDER</label>

                    <select
                      name=''
                      className='form-control'
                      id=''
                      required={true}
                      onChange={(e) => {
                        if (e.target.value === '2') {
                          this.setState({ showGenderPopup: true });
                        } else {
                          this.setState({ showGenderPopup: false });
                        }
                      }}>
                      <option value='' selected hidden>
                        Choose gender
                      </option>
                      <option value='1'>Female</option>
                      <option value='2'>Male</option>
                    </select>

                    {this.state.errors.dob && (
                      <small className='text-red' id='dob-error'>
                        {this.state.errors.dob}
                      </small>
                    )}
                  </div>
                </div>
                <div className='position-relative'>
                  <hr />
                  {/* <div className='my-3 form-title-md'>BOOK YOUR TICKET</div> */}
                  {/* <div className='form-navigation'>
                    <div className='indicators flex-fill'>
                      <div
                        className={
                          'i-1 indicator ' +
                          (this.state.calendarIndex === 0 && 'active')
                        }
                        onClick={() => {
                          this.swiper.slideTo(0);
                        }}>
                        1
                      </div>
                      <div
                        className={
                          'separator flex-fill ' +
                          (this.state.calendarIndex === 1 && 'active')
                        }></div>
                      <div
                        className={
                          'i-2 indicator ' +
                          (this.state.calendarIndex === 1 && 'active')
                        }
                        onClick={() => {
                          this.swiper.slideTo(1);
                        }}>
                        2
                      </div>
                    </div>
                    <div className='form-nav'>
                      <div className='nav-left nav-item mr-2'>
                        <ChevronLeft size={16}></ChevronLeft>
                      </div>
                      <div className='nav-right nav-item'>
                        <ChevronRight size={16}></ChevronRight>
                      </div>
                    </div>
                  </div> */}

                  {!this.state.showAgeLimitPopup &&
                    !this.state.showGenderPopup && (
                      <button
                        disabled={false}
                        className='btn btn-primary mt-3 px-5 btn-round'
                        type='submit'>
                        Register Now
                      </button>
                    )}

                  {/* <div class='swiper-container'>
                    <div class='swiper-wrapper'>
                      <div class='swiper-slide'>
                        <div className='custom-calendar mt-4 my-md-4 '>
                          <div className='calendar-header px-3 py-3'>
                            <span className='text-primary'>JULY</span>
                            <span className='text-primary'>2021</span>
                          </div>
                          <hr className='m-0' />
                          <div className='calendar-days  pt-2'>
                            <div className='day'>MON</div>
                            <div className='day'>TUE</div>
                            <div className='day'>WED</div>
                            <div className='day'>THU</div>
                            <div className='day'>FRI</div>
                            <div className='day'>SAT</div>
                            <div className='day'>SUN</div>
                          </div>
                          <div className='calendar-content pb-3'>
                            <div className='date-parent date-hidden'>
                              <div className='date'>12</div>
                            </div>
                            <div className='date-parent date-hidden'>
                              <div className='date'>13</div>
                            </div>
                            <div className='date-parent date-hidden'>
                              <div className='date'>14</div>
                            </div>
                            <div className='date-parent date-hidden'>
                              <div className='date'>15</div>
                            </div>
                            <div className='date-parent date-hidden'>
                              <div className='date'>16</div>
                            </div>

                            {Object.keys(this.state.bookingData).map((i) => {
                              let d = this.state.bookingData[i];

                              return (
                                <div
                                  onClick={() => {
                                    console.log(i, d);
                                    this.setState({ selected: i });
                                    this.swiper.slideNext();
                                  }}
                                  className='date-parent '
                                  key={i}>
                                  <div
                                    className={
                                      'date ' +
                                      (this.state.selected === i
                                        ? 'active '
                                        : '') +
                                      (d.active === 0 ? 'booked ' : '')
                                    }>
                                    {moment(i).format('DD')}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      <div className='swiper-slide'>
                        <div className='my-auto'>
                          <hr />
                          <div className='slot-selector my-4'>
                            <div className='date-selected'>
                              <span className='day-name'>
                                {!this.state.mobile
                                  ? moment(this.state.selected)
                                      .format('dddd')
                                      .substring(0, 3)
                                      .toUpperCase()
                                  : moment(this.state.selected)
                                      .format('dddd')
                                      .toUpperCase()}
                              </span>
                              <br />
                              <span className='day-date'>
                                {moment(this.state.selected).format('Do')}
                              </span>
                            </div>

                            <div className='slot-list mx-md-5 flex-fill'>
                              {this.state.bookingData[this.state.selected] &&
                                this.state.bookingData[
                                  this.state.selected
                                ].slots.map((d, i) => (
                                  <div
                                    className={
                                      'slot-item-parent ' +
                                      (!d.status && 'disabled')
                                    }>
                                    <div
                                      key={i}
                                      className={
                                        'slot-item flex-fill ' +
                                        (!d.status && 'disabled')
                                      }>
                                      <div className='slot-name'>{d.name}</div>
                                      <div className='slot-time mx-2'>
                                        {moment(d.start).format('hh:mm a')} -{' '}
                                        {moment(d.end).format('hh:mm a')}
                                      </div>
                                      <div className='slot-action'>
                                        {d.status ? (
                                          <button
                                            className='btn btn-sm btn-primary px-md-3'
                                            onClick={() => {
                                              let { inputs } = this.state;
                                              inputs.eventSlotId = d.id;
                                              this.setState({ inputs });
                                            }}>
                                            BOOK
                                          </button>
                                        ) : (
                                          <button
                                            className='btn btn-sm btn-primary px-md-3'
                                            disabled={true}
                                            type='button'
                                            disabled={true}
                                            onClick={() => {
                                              let { inputs } = this.state;
                                              inputs.eventSlotId = d.id;
                                              this.setState({ inputs });
                                            }}>
                                            FULL
                                          </button>
                                        )}
                                      </div>
                                    </div>

                                   
                                  </div>
                                ))}
                            </div>
                          </div>

                          <hr />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {this.state.showAgeLimitPopup && (
                    <div className='over-18-popup p-4'>
                      <div>
                        The SKY program is for teen girls aged 12 to 19.
                        However, you can still join the movement as a SKY Big
                        sis. If you're 20 years and above and want to support
                        SKY with your time and ideas, click this link{' '}
                        <a href='https://forms.gle/i3LYSjxj4EGex5pL7'>
                          https://forms.gle/i3LYSjxj4EGex5pL7
                        </a>
                      </div>
                    </div>
                  )}

                  {this.state.showGenderPopup && (
                    <div className='over-18-popup p-4'>
                      <div>
                        The SKY program is for teen girls aged 12 to 19.
                      </div>
                    </div>
                  )}
                </div>
                {this.state.showParent && (
                  <div className='floating-form p-4 p-md-5'>
                    <div id='form-content'>
                      {this.state.loading && (
                        <div className='text-center'>
                          <div class='sk-chase d-inline-block'>
                            <div class='sk-chase-dot'></div>
                            <div class='sk-chase-dot'></div>
                            <div class='sk-chase-dot'></div>
                            <div class='sk-chase-dot'></div>
                            <div class='sk-chase-dot'></div>
                            <div class='sk-chase-dot'></div>
                          </div>
                          <p className='text-center mt-4'>loading ...</p>
                        </div>
                      )}

                      {!this.state.loading && !this.state.bookingError && (
                        <div className='form-content'>
                          <Check></Check>

                          <p>
                            Congrats! You have booked your tickets to SKY FEST.
                            <br />
                            You should receive an SMS confirmation with your
                            date and time slot. Make sure you don’t miss out.
                          </p>
                          <p>Read the FAQs below for more information.</p>

                          {/* <p>
                            Thank You for registering
                            <br />
                            If you are below the age of 18 years, download the
                            Parental Consent Form below in order to access the
                            event. Remember to come on time and have a heavy
                            breakfast before leaving the house.
                          </p>
                          <p>
                            <a
                              href={
                                'https://skygirlske.com/consent-form?c=' +
                                this.state.code
                              }>
                              https://skygirlske.com/consent-form?c=
                              {this.state.code}
                            </a>
                          </p> */}
                          {/* <small>
                            <a href=''>I haven’t received an SMS</a>
                          </small>
                          <p
                            onClick={() => {
                              this.setState({
                                showParent: false,
                                loading: false,
                              });
                            }}>
                            reset
                          </p> */}
                        </div>
                      )}

                      {!this.state.loading && this.state.bookingError && (
                        <div className='form-content'>
                          <div className='text-center text-red mb-4'>
                            <XCircle size={60}></XCircle>
                          </div>
                          <p>{this.state.bookingError}</p>
                          {/* <p>Please try again </p> */}

                          <p className='text-center'>
                            <button
                              className='btn btn-primary px-4'
                              onClick={() =>
                                this.setState({
                                  showParent: false,
                                  loading: false,
                                  bookingError: false,
                                })
                              }>
                              Retry
                            </button>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </form>

              <div className='col-md-12 info-banners d-md-none p-0'>
                {this.props.cards.map((d, i) => (
                  <div className={'banner-item my-5 item-' + i} key={i}>
                    <div className='banner-content '>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: d.content,
                        }}></div>
                    </div>

                    {d.banner && (
                      <BannerComponent
                        value={d.banner}
                        type={1}></BannerComponent>
                    )}
                  </div>
                ))}
              </div>

              <div className='fest-faq shadow py-4 px-md-4 px-3 mt-4'>
                <h3 className='mb-4 text-primary form-title'>SKY FEST FAQS</h3>

                <div className='faqs'>
                  {this.props.faqs.map((d, i) => (
                    <div
                      className={
                        'faq-item shadow-sm ' +
                        (this.state.activeFAQ === i && 'active')
                      }
                      onClick={() => this.setState({ activeFAQ: i })}
                      key={i}>
                      <div className='faq-title '>
                        <div className='px-3 py-2'>{d.question}</div>
                        <div className='px-3'>
                          {this.state.activeFAQ === i && (
                            <ChevronUp></ChevronUp>
                          )}
                          {this.state.activeFAQ !== i && (
                            <ChevronDown></ChevronDown>
                          )}
                        </div>
                      </div>
                      <div className='faq-content border-top'>
                        <div className='p-3'>{d.answer}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    console.log(this.props.cards);

    this.fetchBookingData();

    this.swiper = new Swiper('.swiper-container', {
      speed: 500,
      spaceBetween: 30,
      navigation: {
        nextEl: '.nav-right',
        prevEl: '.nav-left',
      },
    });

    this.swiper.on('slideChange', (data) => {
      this.setState({ calendarIndex: data.activeIndex });
    });
  }

  fetchBookingData = () => {
    // console.log(global.server);
    window
      .fetch(`${global.server}/booking-data/3`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.json())
      .then((response) => {
        this.setState({ bookingData: response });
      })
      .catch((err) => {});
  };

  formatNumber = (num) => {
    if (!num) {
      return '';
    }

    num = num.replace(/(\+|\s)/g, '');
    num = parseInt(num) + '';
    if (num.substring(0, 3) !== '254') {
      num = '254' + num;
    }
    return num;
  };

  book = () => {
    console.log(this.state.inputs);
    let { inputs } = this.state;

    inputs.phone = this.formatNumber(inputs.phone);
    inputs.phone_parent = this.formatNumber(inputs.phone_parent);
    if (inputs.phone.length !== 12) {
      this.setState({
        errors: {
          phone: '*Phone Number is invalid*',
        },
      });
      setTimeout(() => {
        document
          .querySelector('#phone-error')
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);

      return;
    }

    if (inputs.phone_parent.length !== 12) {
      this.setState({
        errors: {
          phone_parent: '*Phone Number is invalid*',
        },
      });

      setTimeout(() => {
        document
          .querySelector('#phone_parent-error')
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
      return;
    }

    if (moment().diff(inputs.dob, 'years') > 19) {
      this.setState({
        errors: {
          dob: 'This event is for girls between 12 to 19',
        },
      });

      setTimeout(() => {
        document
          .querySelector('#dob-error')
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);

      return;
    }

    this.setState({ loading: true, showParent: true });
    setTimeout(() => {
      document
        .querySelector('#form-content')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);

    window
      .fetch(`${global.server}/event-data`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.code);

        if (response.code === 500) {
          this.setState({ bookingError: response.message, loading: false });
          setTimeout(() => {
            document
              .querySelector('#form-content')
              .scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 0);

          return;
        }

        this.setState({ loading: false, code: response.code });
        setTimeout(() => {
          document
            .querySelector('#form-content')
            .scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default Event;
